import $ from 'jquery';
import colorbox from 'jquery-colorbox';

function addImageLinkClass(querySelector, className) {
	$(querySelector).each(function() {
		if($(this).find('> img, > picture').length) {
			$(this).addClass(className);
		}
	});
}

/**
 * initImageLightBox
 * Launches links to valid image formats into a lightbox (via ColorBox) instead of opening within window
 *
 * @ref: ColorBox - http://www.jacklmoore.com/colorbox/
 */
function initImageLightBox() {
	const imageLinks = '[href*=".jpg"],[href*=".gif"],[href*=".png"]';

	addImageLinkClass(imageLinks, 'enlarge');

	// Only display in lightbox if the current width is above 650px
	$(document).on('click', imageLinks, function(e) {
		e.preventDefault();

		$.colorbox({
			"close": "",
			"width": "auto",
			"maxWidth": "90%",
			"maxHeight": "90%",
			"height": "auto",
			"opacity": 0.7
		});
	});
};


function initHTMLLightBox() {
	const htmlLinks = '.physician-lightbox a.card__body';

	// addImageLinkClass(htmlLinks, 'enlarge');

	let htmlLightBoxWidth  = "800px";
	let htmlLightBoxHeight = "600px";

	// Only display in lightbox if the current width is above 650px
	$(htmlLinks).click( function(e) {
		e.preventDefault();
		if ( $(window).width() < 840 ) {
			htmlLightBoxWidth  = "100%";
			htmlLightBoxHeight = "100%";
		} else {
			htmlLightBoxWidth  = "800px";
			htmlLightBoxHeight = "600px";
		}

		$.colorbox({
			"className": "physician-colorbox",
			"close": "",
			"width": "auto",
			"maxWidth": htmlLightBoxWidth,
			"maxHeight": htmlLightBoxHeight,
			"height": "auto",
			"opacity": 0.7,
			"inline": true,
			"href": $(this).attr('href'),
		});
		return false;
	});
};

/**
 * initVideoLightbox
 * Display YouTube, Vimeo or Wistia videos into a lightbox (via ColorBox) if the viewport is above 650px and the device is not iOS
 *
 * @ref: ColorBox - http://www.jacklmoore.com/colorbox/
 */
function initVideoLightbox() {
	const videoLinks = '[href*="youtube.com"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"]';

	addImageLinkClass(videoLinks, 'play-btn');

	$(document).on('click', videoLinks, function(e) {
		const width = $(window).width();

		if (width > 650) {
			e.preventDefault();

			const $this = $(this);
			let href = $this.attr('href');

			// Return if the current YouTube link is to a profile
			if ($this.parent().attr('data-yt')) {
				return;
			}

			// // Check if this is an iOS Device
			// const isiPad = navigator.userAgent.match(/iPad/i) !== null,
			// 	isiPhone = navigator.userAgent.match(/iPhone/i) !== null;

			// if (isiPad === true || isiPhone === true) {
			// 	return;
			// }

			// Lightbox Widths and Heights
			let cbWidth = 853;
			let cbHeight = 480;

			if (width > 1050) {
				// Bugherd Item 173: Decrease size of video lightbox. Seems like this may be the only scenario this is coming up. 
				// cbWidth = 980;
				// cbHeight = 551; 
			} else if (width < 700) {
				cbWidth = 500;
				bHeight = 281;
			} else if (width < 900) {
				cbWidth = 650;
				cbHeight = 365;
			}

			let hrefParts;

			// YouTube - Full URL
			if (href.indexOf('/watch') >= 0) {
				hrefParts = href.split('?');

				const queryStringParts = hrefParts[1].split('&');
				let videoID;

				for (let i = 0; i < queryStringParts.length; i++) {
					const tmp = queryStringParts[i].split('=');
					// Iterate through the query string parts and set videoID if 'v' is found.
					if (tmp[0] === 'v') {
						videoID = tmp[1];
					}
				}

				href = '//www.youtube.com/embed/' + videoID + '?autoplay=1&hd=1&rel=0';
			}

			// YouTube - Short URL
			if (href.indexOf('youtu.be') >= 0) {
				hrefParts = href.split('/');

				href = '//www.youtube.com/embed/' + hrefParts[hrefParts.length - 1] + '?autoplay=1&hd=1&rel=0';
			}

			// Vimeo
			if (href.indexOf('vimeo.com') >= 0) {
				// Only update the href variable if the URL is not for the Vimeo embed
				if (href.indexOf('player.vimeo.com') === 0) {
					hrefParts = href.split('/');
					href = '//player.vimeo.com/video/' + hrefParts[hrefParts.length - 1] + '?autoplay=1';
				}
			}

			// Wistia
			if (href.indexOf('wistia.com') >= 0) {
				// Only update the href variable if the URL is not for the Wistia embed
				if (href.indexOf('fast.wistia.net') === 0) {
					hrefParts = href.split('/');
					href = '//fast.wistia.net/embed/iframe/' + hrefParts[hrefParts.length - 1] + '?autoplay=1';
				}
			}

			$.colorbox({
				"href": href,
				"close": "",
				"iframe": 1,
				"innerHeight": cbHeight,
				"innerWidth": cbWidth,
				"initialHeight": 200,
				"initialWidth": 200,
				"opacity": 0.7
			});
		}
	});
};

document.addEventListener('DOMContentLoaded', (e) => {
	initImageLightBox();
	initVideoLightbox();
	initHTMLLightBox();
});
