import $ from 'jquery';

var tables = $('.editor-styles table');
tables.each(function() {
	$(this).wrap('<div class="table--responsive"><div class="table--responsive__inner"></div></div>');
});

$(window).smartresize(function() {
	tables.each(function() {
		var table = $(this);
		var container = table.closest('.table--responsive');

		if(table.width() > container.width()) {
			container.addClass('overflow');
		} else {
			container.removeClass('overflow');
		}
	});
}).resize();
