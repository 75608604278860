/**
 * Imports svgxuse.
 *
 * A simple polyfill that fetches external SVGs referenced in <use>
 * elements when the browser itself fails to do so.
 *
 * @see https://www.npmjs.com/package/svgxuse
 *
 * @since 1.0.0
 */
import 'svgxuse';

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
// Uncomment if you want to use the inlineSVGs() function.
import $ from 'jquery';

/**
 * Wrap all SVGs in the menu with a placeholder
 */
function wrapMenuSVGs() {
	var svgs = $('.ubermenu-main img[src$="svg"]');
	svgs.each(function() {
		$(this).wrap('<div class="placeholder thumb-200x120"></div>');
	});
}

/**
 * Replace all svgs reference in img tags, with the actual svg.
 *
 * @since  1.0.0
 */


/**
 * Replace SVGs images with inline
 * @return html
 */
function inlineSVGs() {
	var $svgImages;
	$svgImages = $('img[src$="svg"]:not(.blog-logo):not(.custom-logo):not(.svg-no-inject)').hide();
	return $svgImages.each(function(i, item) {
		var _this = this;
		return $.get(this.src).done(function(data) {
			var $svg, a, nName, nValue, _attr, _i, _len;
			$svg = $(data).find('svg');
			_attr = _this.attributes;
			$.extend(_attr, $svg.attributes);
			for (_i = 0, _len = _attr.length; _i < _len; _i++) {
				a = _attr[_i];
				nName = a.nodeName;
				nValue = a.nodeValue;
				if (nName !== 'src' && nName !== 'style') {
					$svg.attr(nName, nValue);
				}
			}
			return $(_this).replaceWith($svg);
		});
	});
}
window.addEventListener( 'load', wrapMenuSVGs );
window.addEventListener( 'load', inlineSVGs );
