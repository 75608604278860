// import FontFaceObserver from 'fontfaceobserver/fontfaceobserver.standalone';

(function() {
	/**
	 * Prevent Google Maps from loading Roboto fonts.
	 * @link https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font
	 */

	const head = document.getElementsByTagName('head')[0];

	// Save the original method
	const insertBefore = head.insertBefore;

	// Replace it!
	head.insertBefore = function(newElement, referenceElement) {

		if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') >= 0) {
			return;
		}

		insertBefore.call(head, newElement, referenceElement);
	};

	// if the class is already set, we're good.
	// if( document.documentElement.classList.contains( "fonts-loaded" ) ) {
	// 	return;
	// }
	//
	// Promise
	// 	.all([
	// 		new FontFaceObserver( 'SharpSans', {
	// 			weight: 400
	// 		}).load(),
	// 		new FontFaceObserver( 'SharpSans', {
	// 			weight: 500
	// 		}).load(),
	// 		new FontFaceObserver( 'SharpSans', {
	// 			weight: 600
	// 		}).load(),
	// 		new FontFaceObserver( 'SharpSans', {
	// 			weight: 700
	// 		}).load()
	// 	])
	// 	.then(function(){
	// 		document.documentElement.classList.add('fonts-loaded');
	// 		document.cookie = 'childThemeFontsLoaded=true;'
	// 	});
}(this));
