import $ from 'jquery';
import { els } from './variables';
import * as helpers from './helpers';

/**
 * Scroll to anchor and update URL
 */
$(document).on('click', 'a[href*="#"]:not([href="#"])', function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var hash = this.hash;
        var target = $(hash);
        target = target.length ? target : $('[name=' + hash.slice(1) + ']');
        if (target.length) {
            helpers.scrollToEl(target);
            helpers.addHashToURL(hash);
            return false;
        }
    }
});

/**
 * True form reset.
 */
$('button[type="reset"]').on('click', function() {
	var button = $(this);
	var parentForm = button.closest('form');
	helpers.resetForm(parentForm);
	parentForm.trigger('submit');
});

/**
 * Add rel tag to target="_blank" links just in case it isn't already there.
 */
// $('a[target="_blank"]').attr('rel', 'noopener noreferrer');

/**
 * Add play icon to images that link to videos.
 */
	var videoLinks = '[href*="youtube.com"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"]';

	$(videoLinks).each(function(){
		var link = $(this);

	if(!link.hasClass('card__body')) {
		var img = link.find('img, picture');

 		if ( img.length > 0 ) {
			link.addClass('play-btn');

			var imgClass = '';
			if(img.hasClass('alignright')) {
				imgClass = 'alignright';
			} else if(img.hasClass('alignleft')) {
				imgClass = 'alignleft';
			} else if(img.hasClass('aligncenter')) {
				imgClass = 'aligncenter';
			}

			img.removeClass(imgClass);
 			img.wrap('<div class="play-btn-img ' + imgClass + '"></div>');
 		}
	}
	});

/**
 * Scroll to search results if query params are present.
 */
$(window).on('load', function() {
	els.body.addClass('loaded');
	if(els.body.hasClass('tmpl-knowledge-center') || els.body.hasClass('tmpl-advanced-search') || els.body.hasClass('tmpl-events')) {
		if(window.location.search) {
			$('html, body').animate({
				scrollTop: $('#search-results').offset().top - 40
			}, 300);
		}
	}
});

/**
 * Replace pretty single quotes with regular single quotes for search query.
 */
$('input[name="s"]').on('change input', function(e) {
	var input = $(this);
	input.val(input.val().replace("’", "'"));
});
