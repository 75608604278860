import './theme/polyfills';
import './theme/webfonts';
//import './theme/lazyload';
import './theme/object-fit';
import './theme/svgs';
import './theme/header';
import './theme/carousels';
import './theme/lightboxes';
import './theme/center-search';
import './theme/events-search';
import './theme/responsive-tables';
import './theme/sidebar-toggle';
import './theme/tab-pages';
import './theme/addthis';
import './theme/blog';
//import './theme/maps';
import './theme/countdown';
import './theme/word-cycle';
import './theme/misc';
import './theme/stickyfill';
