import $ from 'jquery';

function initWordCycles() {
	const $wordCycles = $('.word-cycle');

	if($wordCycles.length > 0) {
		const wordCycleIntervals = [];

		function wordCycleInterval( $wordCycle ) {
			const $words = $('.word-cycle__item', $wordCycle);
			const $activeWord = $('.word-cycle__item.active', $wordCycle);
			const activeWordIndex = $words.index($activeWord);
			const nextWordIndex = (activeWordIndex + 1) > ($words.length - 1) ? 0 : activeWordIndex + 1;

			$activeWord.removeClass('active');
			$words.eq(nextWordIndex).addClass('active');
		}

		$wordCycles.each(function() {
			const $wordCycle = $(this);
			wordCycleIntervals.push(setInterval(function() { wordCycleInterval( $wordCycle ) }, 3000));
		});
	}
}

document.addEventListener('DOMContentLoaded', initWordCycles);
