import $ from 'jquery';
import hoverintent from 'hoverintent';
import { els, breakpoints } from './variables';

var menu = $('.ubermenu-main', els.header);
var menuItems = $('.ubermenu-nav > .ubermenu-item', menu);
var menuItemsWithChildren = $('.ubermenu-item-has-children', menu);
var toggle = $('.ubermenu-responsive-toggle-main');
var utilities = $('.utilities', els.header);
var activeMenuItem = false;
var lastActiveMenuItem = false;
var utilitiesActive = false;
var menuActive = false;
var ubermenuActiveClass = 'ubermenu-active';

menuItems.each(function() {
	const $item = $(this);
	const item = this;
	hoverintent(
		item,
		function() {
			activeMenuItem = $item;
			$('[data-original]:not([src])').each(function() {
				const $this = $(this);
				$this.attr('src', $this.attr('data-original'));
			});
		},
		function() {
			if(!utilitiesActive) {
				menu.ubermenu('closeSubmenu', activeMenuItem);
				activeMenuItem = false;
			} else {
				console.log('out, but on utilities still');
			}
		}
	);
});

menuItems.on('ubermenuclose', function(e) {
	if(utilitiesActive) {
		menu.ubermenu('openSubmenu', activeMenuItem);
	}
});

menuItemsWithChildren.each(function() {
	var item = $(this);
	var target = item.find('> .ubermenu-target');
	target.after('<span class="ubermenu-target-toggle"></span>');
});

utilities.on('mouseover', function() {
	utilitiesActive = true;
});

utilities.on('mouseout', function() {
	utilitiesActive = false;
});

/**
 * Disable scrolling on the body when menu is open.
 */
toggle.on('ubermenutoggledopen', function(e) {
	els.body.addClass('main-menu-open no-scroll');
});
toggle.on('ubermenutoggledclose', function(e) {
	els.body.removeClass('main-menu-open no-scroll');
});

$(document).on('click', '.ubermenu-item-has-children', function(e) {
	e.stopPropagation();
	var item = $(this);
	var target = $(e.target);
	console.log(item, target);
	if($(window).width() < breakpoints.md.min) {
		var activeClass = 'ubermenu-active-click';
		if(item.hasClass(activeClass)) {
			item.removeClass(activeClass);
			if(target.hasClass('ubermenu-target-toggle')) {
				e.preventDefault();
			}
		} else {
			item.siblings('.' + activeClass).removeClass(activeClass);
			item.addClass(activeClass);
			e.preventDefault();
		}
	}
});

$(document).on('mouseup', '.ubermenu-retractor', function(e) {
	var item = $(this).closest('.ubermenu-item');
	if($(window).width() < breakpoints.md.min && item.hasClass('ubermenu-active-click')) {
		item.removeClass('ubermenu-active-click');
	}
});
