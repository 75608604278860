/**
 * Stickyfill
 *
 * Polyfill for CSS `position: sticky`
 *
 * @see https://github.com/wilddeer/stickyfill
 *
 * @since 1.0.0
 */
import Stickyfill from 'stickyfilljs';

function initStickyfill() {
	const stickyEls = document.querySelectorAll('.fixed-header .site-wrap .site-header');
	Stickyfill.add(stickyEls);
}

document.addEventListener('DOMContentLoaded', initStickyfill);
