import { speed } from './variables';

export function addHashToURL(hash) {
	if (history.pushState) {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
		window.history.pushState({path:newurl},'',newurl);
	}
}

export function scrollToEl(el, offset) {
  // exclude inline colorbox elements from this function
	if (~el.attr('id').indexOf("inline")) {
		return;
	} else {
		$('html, body').animate({
			scrollTop: el.offset().top - offset
		}, speed);
	}

}

export function resetForm($form) {
    $form.find('input:text, input:password, input:file, select, textarea').val('');
    $form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
}
