import $ from 'jquery';

var dateFormat = "mm/dd/yy";
var datepickerArgs = {
	dateFormat: dateFormat
};

function getDate(element) {
	var date;
	try {
		date = $.datepicker.parseDate(dateFormat, element.value);
	} catch (error) {
		date = null;
	}

	return date;
}

var from = $("#event-start-date").datepicker(datepickerArgs).on("change", function() {
	to.datepicker("option", "minDate", getDate(this));
});

var to = $("#event-end-date").datepicker(datepickerArgs).on("change", function() {
	from.datepicker("option", "maxDate", getDate(this));
});
