import $ from 'jquery';
import { Breakpoints } from './breakpoints';

export const breakpoints = new Breakpoints();

export const speed = 300;

export const els = {
	html: $('html'),
	body: $('body'),
	header: $('#js-site-header')
};
