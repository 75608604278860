import $ from 'jquery';

// Blog "Subscribe Now" form reveal
$('#js-subscribe-form').on('click', function(e) {
	e.preventDefault();
	$(this).siblings('.subscribe-bar__form').toggleClass('subscribe-bar__form--open');
});

//Blog archive page multi level dropdown menu
var select = $('.archive-nav__select > select');
var loader = $('.archive-nav__loader');
select.on('change', function() {
	if(select.val() !== '-1') {
		loader.css('display', 'inline-block');
		window.location = select.val();
	}
});
