import $ from 'jquery';
import { els } from './variables';

var viewport = $(window);
var footer = $('.site-footer');
var triggers = $('.sidebar-toggle__trigger');

triggers.click(function() {
	els.body.toggleClass('sidebar-open no-scroll');
});

viewport.on('scroll', function() {
	if(footer.offset().top > (viewport.scrollTop() + viewport.height() - 20)) {
		triggers.parent().removeClass('hide');
	} else {
		triggers.parent().addClass('hide');
	}
});
