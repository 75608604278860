import $ from 'jquery';

/**
 * Hide add this popup when you touch off of it.
 */
$(document).on('touchend', function(e) {
	var target = $(e.target);
	var addThisEl = $('#at15s');
	var shareBtns = $('.addthis_button');
	if(addThisEl.is(':visible') && !target.is(shareBtns)) {
		addthis_close();
	}
});
