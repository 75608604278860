import $ from 'jquery';

function getTimeRemaining(endtime) {
	var t = Date.parse(endtime) - Date.parse(new Date());
	var seconds = Math.floor((t / 1000) % 60);
	var minutes = Math.floor((t / 1000 / 60) % 60);
	var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
	var days = Math.floor(t / (1000 * 60 * 60 * 24));
	return {'total': t, 'days': days, 'hours': hours, 'minutes': minutes, 'seconds': seconds};
}

function initializeClock(clock, endtime) {
	var daysEl = clock.querySelector('.countdown__days');
	var hoursEl = clock.querySelector('.countdown__hours');
	var minutesEl = clock.querySelector('.countdown__minutes');
	var secondsEl = clock.querySelector('.countdown__seconds');

	function updateClock() {
		var t = getTimeRemaining(endtime);

		daysEl.innerHTML = t.days;
		hoursEl.innerHTML = ('0' + t.hours).slice(-2);
		minutesEl.innerHTML = ('0' + t.minutes).slice(-2);
		secondsEl.innerHTML = ('0' + t.seconds).slice(-2);

		if (t.total <= 0) {
			clearInterval(timeinterval);
		}
	}

	updateClock();
	var timeinterval = setInterval(updateClock, 1000);
}

var countdowns = document.querySelectorAll('.countdown');
for(var i = 0; i < countdowns.length; i++) {
	var countdown = countdowns[i];
	var intervalSeconds = parseInt(countdown.getAttribute('data-interval')) * 1000;
	var deadline = new Date(Date.parse(new Date()) + intervalSeconds);
	initializeClock(countdown, deadline);
}
