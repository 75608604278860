import $ from "jquery";
import slick from "slick-carousel";

/**
 * Make each slide in a slick carousel the same height.
 * @param  object 	carousel 	jquery object that has slick Initialized on it.
 */
/**
 * Make each slide in a slick carousel the same height.
 * @param  object 	carousel 	jquery object that has slick Initialized on it.
 * @param  boolean	animate  	Whether or not to animate the height of the slides.
 */
function sameHeightSlides(carousel, animate) {
  /**
   * Setup reused elements.
   */
  const slides = carousel.find(".slick-slide");
  const slickList = carousel.find(".slick-list");
  const slickTrack = carousel.find(".slick-track");

  /**
   * Set height of slides and track to auto.
   */
  slides.height("auto");
  slickList.height("auto");

  /**
   * Get slick track height now that it's auto.
   */
  var slickTrackHeight = slickTrack.height();

  /**
   * Set each slide's height to the height of slick track.
   */
  slides.css({
    height: slickTrackHeight + "px"
  });
}

$(window).on('load', function() {
  /**
   * Initialize all carousels. Slick args can be passed into the php EM_Carousel
   */
  $(".em-carousel").each(function() {
    const $carousel = $(this);
    const $slides = $(".em-carousel__item", $carousel);

    let slickProps = {
      autoplay: true,
      autoplaySpeed: 4000
    };

    if ($carousel.hasClass("hero-carousel")) {
      slickProps.fade = true;
    }

    $carousel.slick(slickProps);

    if ($slides.length === 1) {
      $carousel.addClass("em-carousel--no-controls");
    }

    // Sliding settings
    $carousel.on("afterChange", function(event, slick, currentSlide) {
      // Update autoplay speed according to slide index
      if ($(".slick-current .word-cycle").length > 0) {
        $carousel.slick("slickSetOption", "autoplaySpeed", 16000, true);
      } else {
        $carousel.slick("slickSetOption", "autoplaySpeed", 4000, true);
      }
    });
  });

  $(".slick-cta").each(function() {
    console.log(
      $(this)
        .closest(".layer")
        .find(".slick-cta-controls .inner")
    );
    $(this).slick({
      appendArrows: $(this)
        .closest(".layer")
        .find(".slick-cta-controls .inner"),
      prevArrow:
        "<span class='em-carousel__prev slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></span>",
      nextArrow:
        "<span class='em-carousel__next slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></span>",
      dots: "true",
      dotsClass: "em-carousel__pager",
      adaptiveHeight: "false"
    });
  });

  /**
   * Carousels, whose slides, should be the same height.
   */
  var sameHeightCarousels = $(".featured-posts-carousel, .hero-carousel");

  /**
   * Set slide heights to match on first load.
   */
  sameHeightCarousels.each(function() {
    sameHeightSlides($(this), true);
  });

  /**
   * Set slide heights to match on slick.setPosition.
   */
  sameHeightCarousels.on("setPosition", function() {
    sameHeightSlides($(this), false);
  });
});
