import $ from "jquery";
import slick from "slick-carousel";
import { speed } from "./variables";

function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

// Load more implementation for news/events.
$(".js-load-more").on('click', function(e) {
  e.preventDefault();
  var show_more_of = $(this).attr("rel");
  //console.log(show_more_of);
  $("." + show_more_of).show();
  // $('.slick-slider').slick('setPosition');
  $(this).hide();
});

// Load more implementation for resources.
$(".js-load-more-posts").on('click', function(e) {
  e.preventDefault();

  var $this = $(this);
  var $grid = $(this)
    .closest(".sub-tab.active-main")
    .find(".grid");
  var id = $this
    .closest(".inner")
    .find(".tab__page__sidebar .active-tab + ul input:checked")
    .attr("id");

  var $allItems = $grid.find(".grid__item");
  var $filteredItems = $allItems.filter("." + id);
  var $otherItems = $allItems.not("." + id);

  // Add loaded all class to the grid, so we know to hide the load more button in the future.
  $grid.addClass("loaded-all-" + id);

  if (id === "all") {
    $($allItems)
      .show()
      .removeClass("post-is-hidden");
  } else {
    $($filteredItems)
      .show()
      .removeClass("post-is-hidden");
    $otherItems.hide().addClass("post-is-hidden");
  }

  $(this).hide();

  $(".slick-slider").slick("setPosition");

  return false;
});

$(".tab__pages").each(function() {
  var pages = $(this);

  // add elements to add slick dots to
  pages.before(
    '<nav class="tab__nav" role="navigation"><div class="inner"></div></nav>'
  );

  // store elements for future use
  var pagesNav = pages.prev();
  var pagesDotsContainer = pagesNav.find(".inner");

  /**
   * Bugfix for scenario:
   *
   * When going to News/Events tab and clicking the Events tab, then clicking on 'Resources',
   * the slick height is miscalculated. Fixed by adding a timeout to re-click the active tab label
   * right after slick has finished changing to update the slide height.
   */
  pages.on("afterChange", function(event, slick, currentSlide) {
    setTimeout(function() {
      // console.log("testing");
      $(slick.$slides[currentSlide])
        .closest(".layer--tabs")
        .find(".tab__controls li.slick-active")
        .trigger('click');
      $(".layer--tabs .slick-slider").slick("setPosition");
      // var y = $(window).scrollTop();  //your current y position on the page
      if ($(window).width() < 768) {
        var scrollTopAmount = $(".layer--center-details").innerHeight() + 60;
      }
      if ($(window).width() < 480) {
        var scrollTopAmount = $(".layer--center-details").innerHeight();
      }

      $(window).scrollTop(scrollTopAmount);
    }, 10);
  });

  // initialize page slider
  pages.slick({
    adaptiveHeight: true,
    arrows: false,
    appendDots: pagesDotsContainer,
    customPaging: function(slider, i) {
      return (
        '<span id="' +
        convertToSlug($(slider.$slides[i]).data("label")) +
        '">' +
        $(slider.$slides[i]).data("label") +
        "</span>"
      );
    },
    dots: true,
    dotsClass: "tab__controls",
    draggable: false,
    fade: true,
    speed: speed,
    rows: 0
  });

  // scroll dots to top of screen on click
  var headerHeight = 0;
  if ($("body").hasClass("fixed-header")) {
    headerHeight = $(".fixed-header .site-wrap .site-header").outerHeight();
  }
  pagesNav.click(function() {
    $("html, body").animate(
      {
        scrollTop: pagesNav.offset().top - headerHeight
      },
      speed
    );
  });

  var pagesNavSpacer = $("<div class='spacer'></div>");
  pagesNavSpacer.height(pagesDotsContainer.outerHeight());
  pagesNav.append(pagesNavSpacer);

  // $(window).scroll(function() {
  // 	if(($(window).scrollTop() - pagesNav.offset().top) >= 0) {
  // 		pagesNav.addClass('fixed');
  // 	} else {
  // 		pagesNav.removeClass('fixed');
  // 	}
  // });
});

if ($("#js-hidden-services").length > 0) {
  $("#js-hidden-services").appendTo(".active-tab--downloads__submenu:not(.resources-only)");
}

if ($("#js-hidden-resources").length > 0) {
	$("#js-hidden-resources").appendTo(".active-tab--downloads__submenu.resources-only");
}

if ($("#js-hidden-blogcategories").length > 0) {
  $("#js-hidden-blogcategories").appendTo(".active-tab--blogposts__submenu");
  $(".active-tab--blogposts__submenu").hide();
}

$(".layer--center-details__right__toggle").on('click', function(e) {
  e.preventDefault();
  $(".center__details").toggleClass("active");
  return false;
});

if ($(".tab__page__sidebar").length > 0) {
  $(".cta-bar__close").on('click', function() {
    $(".center__cta").hide();
  });

  // $('li:first-child a', this).addClass('active-tab');

  $(".tab__page__sidebar a", this).on('click', function(e) {
    e.preventDefault();

    $(".active-tab--downloads__submenu").hide();
    $(".active-tab--blogposts__submenu").hide();

    $(".tab__page__sidebar a").removeClass("active-tab");
    $(".sub-tab").removeClass("active-main");

    var which_tab = $(this)
      .attr("href")
      .split("--");

    $("#tab-" + which_tab[1]).addClass("active-main");
    $(this).addClass("active-tab");

    if (which_tab[1] == "downloads") {
      if ($(this).hasClass("active-tab")) {
        $(".active-tab--downloads__submenu").show();
      } else {
        $(".active-tab--downloads__submenu").hide();
      }
    }
    if (which_tab[1] == "blogposts") {
      if ($(this).hasClass("active-tab")) {
        $(".active-tab--blogposts__submenu").show();
      } else {
        $(".active-tab--blogposts__submenu").hide();
      }
    }
    $(".slick-slider").slick("setPosition");
  });
}

$(".tab__controls li").on('click', function() {
  $(".tab__page__sidebar li a").removeClass("active-tab");
  $(".tab__page__sidebar li:first-child a").addClass("active-tab");
  $(".tab__page__main  .sub-tab").removeClass("active-main");
  $(".tab__page__main  .sub-tab:first-child").addClass("active-main");
  $(".active-tab--downloads__submenu").show();
  $(".layer--tabs .slick-slider").slick("setPosition");
  // console.log('test');
  // $('.site-wrap').animate({
  //   scrollTop: $('.layer--tabs').offset().top + 100
  // }, 10);
  //$('.slick-slider').slick('setPosition');
});

$(".sidebar-filters input").on('change', function() {
  var $this = $(this);

  var id = $this.attr("id");
  var $allItems = $this
    .closest(".inner")
    .find(".sub-tab.active-main .grid .grid__item");
  var $filteredItems = $allItems.filter("." + id);
  var $otherItems = $allItems.not("." + id);
  var loadedAll = $(this)
    .closest(".inner")
    .find(".sub-tab.active-main .grid")
    .hasClass("loaded-all-" + id);
  var count = $filteredItems.length;
  var $loadBtn = $(this)
    .closest(".inner")
    .find(".sub-tab.active-main .js-load-more-posts");

  if (id === "all") {
    count = $allItems.length;
  }

  // If there are 9 or less posts, don't show load more.
  if (count < 10) {
    $loadBtn.hide();
  } else {
    if (!loadedAll) {
      $loadBtn.show();
    } else {
      $loadBtn.hide();
    }
  }

  // If container hasn't loaded all posts in this term, only show first 9.
  if (!loadedAll) {
    if (id === "all") {
      $($allItems)
        .slice(0, 9)
        .show()
        .removeClass("post-is-hidden");
      $($allItems)
        .slice(9)
        .addClass("post-is-hidden");
    } else {
      $($filteredItems)
        .slice(0, 9)
        .show()
        .removeClass("post-is-hidden");
      $($filteredItems)
        .slice(9)
        .addClass("post-is-hidden");
      $otherItems.addClass("post-is-hidden");
    }
  } else {
    if (id === "all") {
      $($allItems)
        .show()
        .removeClass("post-is-hidden");
    } else {
      $($filteredItems)
        .show()
        .removeClass("post-is-hidden");
      $otherItems.hide().addClass("post-is-hidden");
    }
  }

  $(".slick-slider").slick("setPosition");
});

$("#js-hidden-hours").on('click', function(e) {
  e.preventDefault();
  $(".hidden-hours").toggle();

  if ($(".hidden-hours").is(":visible")) {
    $(".hidden-hours")
      .parent("tbody")
      .css("padding", "10px");
  } else {
    $(".hidden-hours")
      .parent("tbody")
      .css("padding", "0");
  }
});

$("#js-hidden-hours-2").on('click', function(e) {
  e.preventDefault();
  $(".hidden-hours-2").toggle();

  if ($(".hidden-hours-2").is(":visible")) {
    $(".hidden-hours-2")
      .parent("tbody")
      .css("padding", "10px");
  } else {
    $(".hidden-hours-2")
      .parent("tbody")
      .css("padding", "0");
  }
});

$("a")
  .filter(function() {
    return this.hostname != window.location.hostname;
  })
  .attr("target", "_blank");


if( $(".testimonial__slider").length > 0 ) {
  $(".testimonial__slider").slick({
    infinite: true,
    prevArrow: "<span class='em-carousel__prev slick-prev'></span>",
    nextArrow: "<span class='em-carousel__next slick-next'></span>",
    dots: "true",
    dotsClass: "em-carousel__pager"
  });
  
  $(".testimonial__slider").slick("setPosition");
  
  $(".testimonial__slider").on("init", function(event, slick, direction) {
    $(this).slick("setPosition");
    // console.log(event);
  });
  
  $(".testimonial__slider").on("afterChange", function(event, slick, direction) {
    $(".testimonial__slider").slick("setPosition");
    //console.log(event);
  });
} 


$("#js-toggle-emergency-info").on('click', function(e) {
  e.preventDefault();
  $(".emergency-info__data").toggle();
});

if (window.location.hash.substring(1)) {
  if (window.location.hash.substring(1) == "about") {
    $("#about-us")
      .closest("li")
      .trigger('click');
  }
  if (window.location.hash.substring(1) == "services") {
    $("#treatment--services")
      .closest("li")
      .click();
  }
}

$(".js-goto-tab").on('click', function(e) {
  e.preventDefault();
  var rel = $(this).attr("rel");
  $('a[href$="#tab__page--' + rel + '"').trigger('click');

  $(".sub-tab").hide();
  $("#tab-" + rel).show();
  //console.log(rel);
  switch (rel) {
    case "news":
    case "events":
      $("#newsevents")
        .closest("li")
        .trigger('click');
      if (rel == "events") {
        setTimeout(function() {
          $('.tab__page__sidebar [href="#tab__page--events"]').trigger('click');
        }, 500);
      }
    case "about":
      $("#about-us")
        .closest("li")
        .trigger('click');
      break;
    case "treatment--services":
      $("#treatment--services")
        .closest("li")
        .trigger('click');
      break;
    case "blog":
      $("#resources")
        .closest("li")
        .trigger('click')
      setTimeout(function() {
        $('.tab__page__sidebar [href="#tab__page--blogposts"]').trigger('click');
      }, 500);
      break;
    case "services":
      $("#treatment--services")
        .closest("li")
        .trigger('click');
      break;
    default:
  }
  // $('.slick-slider').slick('setPosition');
});

$(".center-container--mobile__masthead .search-input").hide();
$(".center-container--mobile__masthead .search-button").on('click', function(e) {
  if ($(".center-container--mobile__masthead .search-input").val() == "") {
    $(".center-container--mobile__masthead .search-input").toggle();
    e.preventDefault();
  }
});

function ChangeDiv(width) {
  var surgery = $(".surgery-center").attr("href");
  if (width <= 768) {
    $(".tab__nav")
      .insertAfter($(".site-header--center .center-container--mobile"))
      .addClass("tab__nav--mobile");
    if (!$(".surgery-wrapper").length && surgery != undefined) {
      $(".tab__nav .tab__controls").prepend(
        '<li class="slick-active surgery-wrapper" role="presentation"><span><a href="' +
          surgery +
          '" class="surgery-center--mobile">Surgery Center</a></span></li>'
      );
    }
  } else {
    $(".tab__nav")
      .insertBefore($(".tab__pages"))
      .removeClass("tab__nav--mobile");
    $(".surgery-wrapper").remove();
  }
}

$(function() {
  var onLoadWidth = $(window).width();
  ChangeDiv(onLoadWidth);
  $(window).on('resize', function() {
    var resizeWidth = $(window).width();
    ChangeDiv(resizeWidth);
  });
});
